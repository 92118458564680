import { useId } from 'react';

import useTranslation from '@hooks/useTranslation';

import styles from './RelatedPosts.module.css';

function RelatedPosts({ children }: React.PropsWithChildren) {
    const { t } = useTranslation('pages/blog/single/index');
    const id = useId();

    return (
        <section
            className={styles.related}
            aria-labelledby={id}
        >
            <div className={styles.container}>
                <h2
                    id={id}
                    className={styles.title}
                >
                    {t('relatedPostsHeader')}
                </h2>
                <div className={styles.list}>{children}</div>
            </div>
        </section>
    );
}
export default RelatedPosts;
