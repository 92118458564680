import React from 'react';

import { PostContentProps } from '@customTypes/blog/types';

import StyleBlockGutenberg from './StyleBlockGutenberg';

export default function PostContent({ contentHtml, stripCss }: PostContentProps): JSX.Element {
    return (
        <>
            <StyleBlockGutenberg />
            <style
                data-style="v2"
                dangerouslySetInnerHTML={{ __html: stripCss }}
            />
            <div className="single-post-content">
                <div
                    className="entry-content wp-block-post-content has-global-padding is-layout-constrained wp-embed-responsive"
                    dangerouslySetInnerHTML={{ __html: contentHtml }}
                ></div>
            </div>
        </>
    );
}
