/**
 *
 * @description Style tymczasowe na czas aktualizacji wszystkich postów
 */
export default function Style() {
    return (
        <>
            <style
                jsx
                global
            >{`
                .wp-block-getresponse-table-of-contents a {
                    color: var(--wp--preset--color--deep-sky-blue);
                }
                .wp-block-getresponse-table-of-contents a:hover {
                    color: var(--wp--preset--color--hover-deep-sky-blue);
                }
                .wp-block-getresponse-table-of-contents .gr-toc__title-wrap {
                    outline: none;
                    &::marker {
                        content: '';
                        font-size: 0;
                        display: none;
                    }
                    &::-webkit-details-marker {
                        content: '';
                        font-size: 0;
                        display: none;
                    }
                }
            `}</style>
        </>
    );
}
