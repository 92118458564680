import * as React from 'react';
import { useState } from 'react';

import { FacebookIcon, FacebookShareButton, LinkedinIcon, LinkedinShareButton, TwitterIcon, TwitterShareButton } from 'next-share';

import useTranslation from '@hooks/useTranslation';
import clsx from 'clsx';

import styles from './ShareBar.module.css';
import { ReactComponent as ClipboardIcon } from './assets/clipboard.svg';

interface ShareBarProps {
    postUrl: string;
}

export default function ShareBar({ postUrl }: ShareBarProps) {
    const [isCopied, setIsCopied] = useState(false);
    const [isError, setIsError] = useState(false);
    const { t } = useTranslation('pages/blog/single/index');
    const copyLink = () => {
        try {
            navigator.clipboard
                .writeText(postUrl)
                .then(() => {
                    setIsCopied(true);
                    setTimeout(() => setIsCopied(false), 1000);
                })
                .catch(() => {
                    setIsError(true);
                    setTimeout(() => setIsError(false), 1000);
                });
        } catch (err) {
            setIsError(true);
            setTimeout(() => setIsError(false), 1000);
        }
    };
    return (
        <>
            <span className={styles.shareLabel}>{t('Share')}</span>
            <div className={styles.shareBar}>
                <FacebookShareButton url={postUrl}>
                    <span className={clsx(styles.shareButton, styles.facebook)}>
                        <FacebookIcon
                            size={32}
                            bgStyle={{ fill: 'none' }}
                        />
                        <span>{t('Share')}</span>
                    </span>
                </FacebookShareButton>
                <TwitterShareButton url={postUrl}>
                    <span className={clsx(styles.shareButton, styles.twitter)}>
                        <TwitterIcon
                            size={32}
                            bgStyle={{ fill: 'none' }}
                        />
                        <span>{t('Share')}</span>
                    </span>
                </TwitterShareButton>
                <LinkedinShareButton url={postUrl}>
                    <span className={clsx(styles.shareButton, styles.linkedin)}>
                        <LinkedinIcon
                            size={32}
                            borderRadius={2}
                            bgStyle={{ fill: 'none' }}
                        />
                        <span>{t('Share')}</span>
                    </span>
                </LinkedinShareButton>
                <div className={styles.btn}>
                    <button
                        onClick={copyLink}
                        className={clsx(styles.shareButton, styles.clipboard)}
                        role={'button'}
                        aria-label={t('copy')}
                    >
                        <ClipboardIcon />
                        {isCopied ? !isError ? <span>{t('copied')}</span> : <span>{t('copy_error')}</span> : <span>{t('copy')}</span>}
                    </button>
                </div>
            </div>
        </>
    );
}
