export default function StyleBlockGutenberg() {
    return (
        <>
            <style
                jsx
                global
            >{`
                .single-post-content {
                    margin: auto calc(-1 * var(--wp--style--root--padding-right, 20px));
                    container-name: post-content;
                    container-type: inline-size;
                }
                @media screen and (min-width: 980px) {
                    .single-post-content {
                        margin: auto;
                    }
                }
                .single-post-content [id] {
                    scroll-margin-block: 100px;
                }
                body .is-layout-constrained {
                    --wp--style--root--padding-right: 20px;
                    --wp--style--root--padding-left: 20px;
                }
                .is-layout-constrained ul a,
                .is-layout-constrained ol a {
                    color: var(--primary-link-color);
                }
                .is-layout-constrained ul a:hover,
                .is-layout-constrained ol a:hover {
                    color: #00a2ff;
                }
            `}</style>
        </>
    );
}
