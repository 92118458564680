import React from 'react';

import SinglePostDetails from '../SinglePostDetails';
import styles from './SinglePostHeader.module.css';

export interface HeaderProps {
    title: string;
    author?: string;
    date?: string;
    readTime?: string;
    authorImage?: string;
    authorLink?: string;
}

export default function SinglePostHeader({ title, date, author, readTime, authorImage, authorLink }: HeaderProps) {
    return (
        <header className={styles.header}>
            <h1>{title}</h1>
            <SinglePostDetails
                className={styles.details}
                type="long"
                date={date}
                author={author}
                readTime={readTime}
                authorImage={authorImage}
                authorLink={authorLink}
            />
        </header>
    );
}
