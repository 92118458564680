import React from 'react';

import useTranslation from '@hooks/useTranslation';

import styles from './RelatedFeatures.module.css';

interface RelatedFeaturesProps {
    features: Array<{
        features_url: string;
        icon: string;
        name: string;
    }>;
}

function RelatedFeatures({ features }: RelatedFeaturesProps) {
    const { t } = useTranslation('pages/blog/single/index');

    if (features.length === 0) {
        return null;
    }
    return (
        <aside className={styles.related}>
            <h2 className={styles.title}>{t('relatedFeatures')}</h2>
            <ul className={styles.list}>
                {features.map((feature) => (
                    <li key={feature.name}>
                        <a href={feature.features_url}>
                            <div
                                className={styles.icon}
                                style={{ '--icon': `url(${feature.icon})` } as React.CSSProperties}
                            ></div>
                            <span>{feature.name}</span>
                        </a>
                    </li>
                ))}
            </ul>
        </aside>
    );
}
export default RelatedFeatures;
