import React from 'react';

import getConfig from 'next/config';

import CustomLink from '@components/global/CustomLink';

import clsx from 'clsx';

import styles from './SinglePostAuthor.module.css';

const { publicRuntimeConfig } = getConfig();

export interface HeaderProps {
    author: string;
    authorImage?: string;
    authorLink?: string;
    description?: string;
}

export default function SinglePostAuthor({ authorImage, author, authorLink, description }: HeaderProps) {
    return (
        <footer className={clsx(styles.footer, !description && styles.middle)}>
            <div className={styles.author}>
                {authorImage && (
                    <CustomLink href={{ pathname: authorLink }}>
                        <img
                            src={`${publicRuntimeConfig.resizer}80x/${authorImage}`}
                            srcSet={`${publicRuntimeConfig.resizer}160x/${authorImage} 2x`}
                            alt={author}
                            height="80"
                            width="80"
                        />
                    </CustomLink>
                )}
            </div>
            <CustomLink
                href={{ pathname: authorLink }}
                className={styles.name}
            >
                {author}
            </CustomLink>
            {description ? (
                <div
                    className={styles.description}
                    dangerouslySetInnerHTML={{ __html: description }}
                />
            ) : null}
        </footer>
    );
}
