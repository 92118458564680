import * as React from 'react';

import Head from 'next/head';
import { useRouter } from 'next/router';

import { canonical } from '@helpers/alternates';

import { BlogPost } from '@customTypes/blog/types';

interface JsonLdProps {
    post: BlogPost;
}

export default function JsonLdWrapper({ post }: JsonLdProps) {
    const { pathname, locale } = useRouter();

    const urlPost = pathname.replace('[slug]', post.slug).replace('[id]', post.id.toString());
    const canonicalUrl = encodeURIComponent(canonical(urlPost, locale));

    const desc = post.content_html.replace('\n', '').split('</p>')[0];
    const strippedString = desc.replace('<p>', '').replace(/(<([^>]+)>)/gi, '');

    const ldJson = {
        '@context': 'https://schema.org',
        '@type': 'BlogPosting',
        mainEntityOfPage: {
            '@type': 'WebPage',
            '@id': canonicalUrl,
            url: canonicalUrl,
        },
        headline: post.metaTitle,
        image: post.media ?? undefined,
        datePublished: post.datePublished ?? undefined,
        dateModified: post.dateModified ?? undefined,
        author: [
            {
                name: post?.author?.name ?? 'GetResponse',
                image: post?.author?.avatar2x ?? undefined,
                description: post?.author?.description ?? undefined,
                jobTitle: post?.author?.author_job_title ?? undefined,
                url: post?.author?.author_link ?? undefined,
                '@type': post?.author?.name == 'GetResponse' ? 'Organization' : 'Person',
            },
        ],
        publisher: {
            '@type': 'Organization',
            name: 'GetResponse',
            url: 'https://www.getresponse.com',
            logo: {
                '@type': 'ImageObject',
                url: 'https://upload.wikimedia.org/wikipedia/commons/2/2c/GetResponse_Logo.png',
            },
        },
        description: strippedString,
    };

    return (
        <Head>
            <script
                type="application/ld+json"
                dangerouslySetInnerHTML={{
                    __html: JSON.stringify(ldJson),
                }}
            />

            {post.structured_data &&
                post.structured_data.map((data, index) => {
                    return (
                        data && (
                            <script
                                key={index}
                                type="application/ld+json"
                                dangerouslySetInnerHTML={{
                                    __html: data,
                                }}
                            />
                        )
                    );
                })}
        </Head>
    );
}
